/* Container styles */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  color: #333;
  font-family: Arial, sans-serif;
}

/* Main heading */
.privacy-policy h1 {
  color: #00695c;
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
}

/* Section headings */
.privacy-policy h2 {
  color: #004d40;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 22px;
}

/* Sub-headings */
.privacy-policy h3 {
  color: #00796b;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

/* Paragraph styles */
.privacy-policy p {
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 16px;
}

/* List styles */
.privacy-policy ul {
  margin-left: 20px;
  margin-bottom: 20px;
  list-style-type: disc;
}

.privacy-policy li {
  margin-bottom: 10px;
}

/* Bold text in list items */
.privacy-policy li strong {
  color: #bf360c;
}

/* Links */
.privacy-policy a {
  color: #0288d1;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 600px) {
  .privacy-policy {
      padding: 30px 15px;
  }

  .privacy-policy h1 {
      font-size: 24px;
  }

  .privacy-policy h2 {
      font-size: 20px;
  }

  .privacy-policy h3 {
      font-size: 18px;
  }
}