/* ChatInterface.css */

/* Chat container fills the viewport and accounts for the footer */
.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Subtract the footer height */
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  overflow: hidden; /* Prevent overflow issues */
}

/* Scrollable chat messages area */
.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #dce1e2; /* Light cyan */
  z-index: 1; /* Ensures it's below the input area */
}

/* Styling for chat messages */
.chat-message {
  margin-bottom: 15px;
  max-width: 70%;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #5dad64; /* Light green */
  color: #020202; /* Dark text */
  padding: 10px;
  border-radius: 15px 15px 0 15px;
}

.chat-message.bot {
  align-self: flex-start;
  background-color: #52b2ed; /* Light blue */
  color: #000000; /* Dark text */
  padding: 10px;
  border-radius: 15px 15px 15px 0;
}

/* Fixed input area above the footer */
.chat-input-area {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0; /* Fixed to the bottom of the container */
  width: 100%; /* Ensure it spans the full width */
  z-index: 2; /* Ensure it stays above messages */
  box-sizing: border-box; /* Prevent layout shifts */
}

/* Input field styling */
.chat-input-area input {
  flex: 1;
  padding: 10px;
  border: 2px solid #000000;
  border-radius: 4px;
  margin-right: 10px;
}

/* Button styling */
.chat-input-area button {
  padding: 10px 20px;
  background-color: #00695c; /* Dark teal */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input-area button:hover {
  background-color: #004d40; /* Darker teal */
}

/* Ensure messages take full width on smaller screens */
@media (max-width: 768px) {
  .chat-message {
    max-width: 100%;
  }
}