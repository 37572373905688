.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px); /* Deduct the height of the footer */
  margin: 0;
  padding: 0;
 
}

@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}