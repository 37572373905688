.terms {
    padding: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;  
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); 
}

.terms h1, .terms h2 {
    color: #00695c; 
}

.terms p, .terms address {
    margin-bottom: 16px; 
}

.terms a {
    color: #00695c; 
    text-decoration: none; 
}

.terms a:hover {
    text-decoration: underline; 
}