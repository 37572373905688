/* AboutUs.css */

/* Main container */
.about-us {
  max-width: 800px;
  margin: 20px auto; /* Reduced top and bottom margin to close the gap */
  padding: 20px;
  padding-bottom: 60px; /* Adds space at the bottom to avoid overlapping with the footer */
  background-color: #ffffff; /* White background for readability */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Heading styles */
.about-us h1 {
  color: #00695c; /* Dark teal */
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.about-us h2 {
  color: #004d40; /* Slightly darker teal for subheadings */
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
}

/* Paragraph styles */
.about-us p {
  color: #333;
  font-size: 16px;
  margin-bottom: 15px;
}

/* Strong emphasis for company name */
.about-us p strong {
  color: #00695c;
}

/* Emphasis for quotes */
.about-us p em {
  color: #004d40;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 600px) {
  .about-us {
    padding: 15px;
    margin: 10px auto; /* Adjusted for smaller screens */
    padding-bottom: 80px; /* Adds extra space at the bottom for smaller screens */
  }

  .about-us h1 {
    font-size: 28px;
  }

  .about-us h2 {
    font-size: 20px;
  }

  .about-us p {
    font-size: 14px;
  }
}