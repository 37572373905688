/* ContactUs.css */

/* Main container */
.contact-us {
  max-width: 800px;
  margin: 20px auto; /* Reduced top and bottom margin to close the gap */
  padding: 20px;
  background-color: #ffffff; /* White for readability */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Heading styles */
.contact-us h1 {
  color: #00695c; /* Dark teal for branding */
  margin-bottom: 20px;
  text-align: center;
  font-size: 28px;
}

/* Company information styles */
.contact-us .company-info {
  margin-bottom: 30px;
  color: #555;
  text-align: center;
  font-size: 16px;
}

.contact-us .company-info a {
  color: #00695c;
  text-decoration: none;
}

.contact-us .company-info a:hover {
  text-decoration: underline;
}

/* Form styles */
.contact-us form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 60px;
}

/* Input fields and textarea */
.contact-us input,
.contact-us textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: #f9f9f9;
}

.contact-us input::placeholder,
.contact-us textarea::placeholder {
  color: #aaa;
}

.contact-us input:focus,
.contact-us textarea:focus {
  border-color: #00695c; /* Highlighted on focus */
  outline: none;
}

/* Submit button */
.contact-us button {
  background-color: #00695c; /* Dark teal */
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Adds spacing above button */
}

.contact-us button:hover {
  background-color: #004d40; /* Darker teal */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .contact-us {
    padding: 15px;
    margin: 10px auto; /* Adjust top and bottom margins for smaller screens */
  }

  .contact-us h1 {
    font-size: 24px;
  }

  .contact-us .company-info {
    font-size: 14px;
  }

  .contact-us input,
  .contact-us textarea {
    font-size: 14px;
  }

  .contact-us button {
    font-size: 14px;
  }
}