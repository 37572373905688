.footer {
  position: fixed; /* Keeps the footer at the bottom of the viewport */
  bottom: 0;
  left: 0;
  width: 100%; /* Ensures the footer spans the entire width */
  height: 40px; /* Reduces height for less intrusion */
  background-color: #3b3b3b; /* Dark background for footer */
  color: #fff; /* White text color */
  text-align: center;
  display: flex; /* Using flexbox */
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  padding: 0 10px; /* Reduces padding, adjusts horizontal spacing */
  z-index: 1000; /* Ensures the footer is above other elements */
  box-sizing: border-box; /* Ensures padding is included in height */
}

.footer-link {
  color: #fff; /* White color for links */
  margin: 0 5px; /* Reduced margin around the links */
  text-decoration: none; /* No underline */
  font-size: 14px; /* Reducing font size to fit links better */
}

.footer-link:hover {
  text-decoration: underline; /* Underline on hover */
}